import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Modal from '../../../sharedComponents/Modal'
import closeIcon from '../../../assets/images/cancel.svg';
import { classify } from '../../../common/Utility';
import InputField from '../../../sharedComponents/InputField/InputField';
import SelectMenu from '../../../sharedComponents/SelectMenu/SelectMenu';
import toast from 'react-hot-toast';
import slugify from 'slugify';

function DepositModalLink({ show, closeModal }) {
  const { currentIdea } = useSelector(state => state.bucketList);
  const [generatedLink, setGeneratedLink] = useState("");
  useEffect(() => {
  
    return () => {
      setGeneratedLink("")
    }
  }, [])
  
  const copyText = () => {
    navigator.clipboard.writeText(generatedLink);
    toast.success("Text Copied successfully!")
  }
  function formatDateToDDMMYYYY(dateStr) {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  }
  const onSubmitForm = (e) => {
    if (!show) {
      e.preventDefault();
      return;
    }
    e.preventDefault();

    try {
      const formElements = e.target.elements;
      const tripName = currentIdea?.idea_title;
      const slug = slugify(tripName || "", { lower: true });
      const merchantId = currentIdea?.merchant_id;
      let ideaLink = `https://xplor.saathi.money/${merchantId}/ideas/${slug}/sign-up?`;
      console.log(currentIdea)
      let query = "";
      for (let i = 0; i < formElements.length; i++) {
        const ele = formElements[i];
        const name = ele.name;
        if (name.length > 0 && ele.value?.length>0) {
          query+=`${name}=${ele.value}&`;
        }
      }
      const date = document.getElementById("dateOfTrip")?.value

      query += `dateOfTrip=${formatDateToDDMMYYYY(date)}`;
      setGeneratedLink(encodeURI(`${ideaLink}${query}`));
      toast.success("Link Generated")
    } catch (error) {
      console.log(error);
      
    }
  }
  return (
    <Modal show={show} onClose={closeModal} className={classify(['p-8'])}>
      <div className="flex justify-end">
        <button className='outline-none border-none' onClick={closeModal}>
          <img className='w-9 h-9' src={closeIcon} alt="close" />
        </button>
      </div>
      <div className='container flex flex-col items-center px-4'>
        <h1 className='text-[24px]'>
          Generate Deposit Link
        </h1>
        <form onSubmit={onSubmitForm} className='flex w-full flex-col items-center my-4'>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField req={true} type='number' min={0} name='noOfMembers' label="Number of Members" />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField req={true} type='number' min={0} name='price' label="Total Cost of Trip" />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField req={true} type='number' min={0} name='depositAmt' label="Deposit Amount" />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField req={false} type='text' min={0} name='groupGoalCode' label="Group Goal Code" />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <label className='block text-sm font-medium text-gray-700'>
              Date of Travel
            </label>
            <div className='relative mt-1 rounded-md shadow-sidebarActive w-full'>
              <input
                min={new Date().toISOString().split("T")[0]}
                type="date"
                className="block w-full h-10 rounded-md border border-inputBorder pl-7 pr-7 outline-none sm:text-sm"
                name=""
                id="dateOfTrip" />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
            <div className='w-full md:w-full'>
              <button onClick={closeModal} className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center' >
                Cancel
              </button>
            </div>
            <div className='w-full md:w-full'>
              <button type='submit' className='bg-btnPink shadow-sidebarActive disabled:opacity-50 rounded-lg h-10 w-full flex justify-center items-center text-white' >
                Generate Link
              </button>
            </div>
          </div>
        </form>
        
        {generatedLink.length>0 && (
          <>
            <div className='mt-4 w-full md:w-4/5'>
              <div className="relative rounded-md shadow-sidebarActive w-full">
                <input
                  type="text"
                  value={generatedLink}
                  readOnly
                  className="block w-full h-10 rounded-md border border-inputBorder pl-7 pr-20 outline-none sm:text-sm"
                />
                <button
                  type="button"
                  onClick={copyText}
                  className="absolute right-2 top-1/2 -translate-y-1/2 bg-btnPink text-white px-3 py-1 rounded-md text-sm hover:opacity-90"
                >
                  Copy
                </button>
              </div>
            </div>
          </>
        )}

      </div>
    </Modal>
  )
}

export default DepositModalLink